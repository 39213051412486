  <div class="reservation">
    <div class="row">
      <div class="col-12 customOverflow">
        <div class="row">
          <div class="col-12">
            <div [ngClass]="{'header': translate.is_arabic, 'headerEn': !translate.is_arabic}">
              <p>{{translate.data.reservationProfile.myReservation}}</p>
            </div>
            <div class="bodyy ">
              <div class="container">
                <div class="noData" *ngIf="reservations?.length == 0">
                  <h3>{{translate.data.reservationProfile.noReservation}}</h3>
                  <p>{{translate.data.reservationProfile.desc}}</p>
                  <button>{{translate.data.favourite.reserve}}</button>
                </div>
                <div *ngFor="let reservation of reservations">
                  <div class="header header-card">
                    <p>{{translate.data.reservationProfile.numberOfReservation}} #{{reservation.id}}</p>
                    <div class="buttons">
                      <button class="edit-card"><img src="../../../assets/profile/icons/pencil.png">{{translate.data.reservationProfile.edit}}</button>
                      <button class="remove" (click)="deleteReservation(reservation.id)"><img src="../../../assets/profile/icons/garbage.png" >{{translate.data.reservationProfile.remove}}</button>
                    </div>
                  </div>
                  <div class="body-card">
                    <div class="cont-1" >
                      <div class="con-2">
                        <img class="main-img" src="{{sharedService.ipRootImg + reservation?.image}}"  alt="">
                        <img class="heart-img" src="../../../assets/caravan/Web 1920 – 33/Group 10409.svg" alt="">
                      </div>
                      <div [ngClass]="{'cont-3': translate.is_arabic, 'cont-3En': !translate.is_arabic}">
                        <div class="cont-3-header">
                          <img class="twenty-img" src="../../../assets/caravan/Group 10466.svg" alt="">
                          <img class="stars-img" src="../../../assets/caravan/Group 17.svg" alt="">
                        </div>
                        <p class="main-title-cont-3">{{reservation?.name}}</p>
                        <div class="cash">
                          <p class="cash-l">{{reservation?.pricePerDay}}</p>
                          <p>{{translate.data.reservationProfile.price}}</p>
                        </div>
                        <div class="box-container">
                          <div class="d-flex flex-wrap">
                            <div class="div">
                              <p>{{translate.data.reservationProfile.seat}} {{reservation?.seatNo}}</p>
                              <img src="../../../assets/caravan/office-chair.svg" alt="">
                            </div>
                            <div class="div">
                              <p>{{translate.data.reservationProfile.seat}} {{reservation?.bedsNo}}</p>
                              <img src="../../../assets/caravan/bed.svg" alt="">
                            </div>
                            <div class="map-title-cont">
                              <img src="../../../../assets/Content/location-pin/location-pin.png" />
                              <div>
                                <p class="map-title-p1">{{translate.data.reservationProfile.region}}</p>
                                <p class="map-title-p2">{{reservation[translate.data.requestdData.carGenCity]}}</p>
                              </div>
                            </div>
                          </div>
                          <!-- <div class="buttons">
                            <button class="edit-card"><img src="../../../assets/profile/icons/pencil.png">تعديل</button>
                            <button class="remove"><img src="../../../assets/profile/icons/garbage.png">حذف</button>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<!-- <router-outlet></router-outlet> -->


