import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import { PagesService} from '../../../pages/pages.service';
import { SharedService } from 'src/app/shared/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslationService } from 'src/app/shared/translation.service';


@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss']
})
export class ReservationComponent implements OnInit {

    // data of reservation
    reservations:any;

  constructor(
    private pagesService: PagesService,
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    public sharedService: SharedService,
    public translate: TranslationService,
  ) { }

  ngOnInit(): void {
    //get user reservation
    this.spinner.show();
    this.pagesService.getUserReservations().subscribe(
      (response: any) => {
        this.reservations = response.data;
        console.log("user reservation",this.reservations);
        this.spinner.hide();
      },
      (error:any) => {
        console.log(error);
        this.spinner.hide();
      }
    )
  }

  // delete reservation
  deleteReservation=(id:any)=>{
    console.log("delete enter");

    this.pagesService.deleteReservation(id).subscribe(
      (response: any) => {
        console.log("delete",response);
        this.reloadComponent();
      },
      (error:any) => {
        console.log(error);
      }
    )
  }

  reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
  }

}
